(function($) {
  $( document ).ready(function() {
    $('.slideContainer').slick({
      dots: true,
      infinite: true,
      arrows: false,
      speed: 300,
      autoplay: true,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      adaptiveHeight: true,
      //centerMode: false,
      variableWidth: false,
    });
  })
})(jQuery);
