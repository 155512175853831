(function($) {
  $( document ).ready(function() {
    $('body .gform_wrapper .top_label div.ginput_container.ginput_container_select').prev().css('padding-top', 15);

    $('#marketoForm').iFrameResize({
      log:false,
      heightCalculationMethod: 'grow'
    }, '#marketoForm')

    $('#marketoFormCategory').iFrameResize({
      log:false,
      heightCalculationMethod: 'grow'
    }, '#marketoFormCategory')

    var $element = $(".marketoForm-category");
    var lastHeight = $(".marketoForm-category").css('height');
    function checkForChanges()
    {
        if ($element.css('height') != lastHeight)
        {
          $('.grid--categoryCtas .grid-1of1--palm .contentCta').matchHeight()
          lastHeight = $element.css('height');
        }

        setTimeout(checkForChanges, 500);
    }
    checkForChanges()
  })
})(jQuery);

