(function($) {
  $( document ).ready(function() {

    $('.btn--career').click(function() {
      $('body').toggleClass('modalActive');
      $('.modal--career, .overlay--career').toggleClass('active');
    });

    $('.close--career').click(function() {
      $('body').toggleClass('modalActive');
      $('.modal--career, .overlay--career').toggleClass('active');
    });

    $('.overlay--career').click(function() {
      $('body').toggleClass('modalActive');
      $('.modal--career, .overlay--career').toggleClass('active');
    });
  })
})(jQuery);
