(function($) {

  var viewport = viewport();
  function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return e[ a+'Width' ];
  }

  function removeHash() {
    var scrollV, scrollH, loc = window.location;
    if ("pushState" in history)
      history.pushState("", document.title, loc.pathname + loc.search);
    else {
      // Prevent scrolling by storing the page's current scroll offset
      scrollV = document.body.scrollTop;
      scrollH = document.body.scrollLeft;

      loc.hash = "";

      // Restore the scroll offset, should be flicker free
      document.body.scrollTop = scrollV;
      document.body.scrollLeft = scrollH;
    }
  }

  var target = window.location.hash,
    target = target.replace('#', '');

  removeHash()

  $(window).on('load', function() {
    var anchorOffset = $('.nav').outerHeight()
    var offset = 0;
    var speed = 1000;

    if (target && $('#' + target).length ) {
      $('html,body').animate({
        scrollTop: $('#' + target).offset().top - anchorOffset
      }, speed);
    }
  });

  $( document ).ready(function() {
    var anchorOffset = $('.nav').outerHeight()
    var offset = 0;
    var speed = 1000;

    $('a[href^="#"]:not([href="#"]), a[href^="/#"]:not([href="#"])').click(function(e) {
      if (location.pathname != '/' && $(this).attr('href').split('#')[0] == '/') {
        window.location.replace($(this).attr('href'));
      } else {
        e.preventDefault()

        target = $(this).attr('href').split('/')[1]

        if(/^#/.test(target) === true && $(target).length) {
          var target = $(this.hash);

          $("html,body").animate({
              scrollTop: target.offset().top - anchorOffset
          }, speed);
        }
      }
    });

    $(".externalLink, .externalLink a").attr("target","_blank");
  })
})(jQuery);
