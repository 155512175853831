(function($) {
  $( document ).ready(function() {
    $('img[src$=".gif"]').each(function(index, el) {
      var $target = $(this)
      var source = $(this).attr('src').split(".gif")[0] + '.png'
      $.get(source)
      .done(function() {
        $target.attr('src', source);
        $target.addClass('gif')

        var inview = new Waypoint.Inview({
          element: $target,
          enter: function(direction) {

          },
          entered: function(direction) {
            var $target = $(this.element[0])
            var source = $target.attr('src').split(".png")[0] + '.gif'
            source = source.replace('.gif.gif', '.gif')
            $target.attr('src', source);
          },
          exit: function(direction) {

          },
          exited: function(direction) {
            var $target = $(this.element[0])
            var source = $target.attr('src').split(".gif")[0] + '.png'
            source = source.replace('.png.png', '.png')
            $target.attr('src', source);
          }
        })
      }).fail(function() {

      })
    });

    $('.gifVideo').each(function(index, el) {
      var $target = $(this)[0]

      var inview = new Waypoint.Inview({
        element: $target,
        enter: function(direction) {

        },
        entered: function(direction) {
          $target.play()
        },
        exit: function(direction) {

        },
        exited: function(direction) {
          $target.pause()
          $target.currentTime = 0
          $target.load()
        }
      })
    });
  })
})(jQuery);
