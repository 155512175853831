(function($) {
  $( document ).ready(function() {
    // FastClick initializer
    FastClick.attach(document.body)

    // matchHeights
    $('.iconGridItem-icon').matchHeight()
    $('.iconGridItem-aboveTitle').matchHeight()
    $('.iconGridItem-title').matchHeight()
    $('.iconGridItem-text').matchHeight()
    $('.teamMember-fullName').matchHeight()
    $('.teamMember-jobTitle').matchHeight()
    $('.careerPosting-department').matchHeight()
    $('.careerPosting-title').matchHeight()
    $('.grid--categories .grid-1of3 .categoryItems .cardItem .cardItem-contentContainer').matchHeight()
    $('.grid--search .grid-1of3 .cardItem .cardItem-contentContainer').matchHeight()
    $('.grid--categories .grid-1of3 .categoryItems .categoryList').matchHeight()
    $('.grid--categoryCtas .grid-1of1--palm .contentCta').matchHeight()


    if ($(window).width() > 767 && $(window).width() < 1024) {
      $('.block--hero--single .container').each(function(index, el) {
        var height = $(this).children('.heroSingle').height()
        $(this).height(height)
      });
    }

    var footerCalloutMargin = $('.footerCallout').height() / 2 * -1
    if ($(window).width() > 745 && !$('.page-template-template-categories').length || $(window).width() > 745 && $('.page-template-template-categories').length && $('.blocksCategories').length) {
      $('.footerCallout').css('margin-top', footerCalloutMargin);
    }

    var pinnedOffset = (parseInt($('.block--imageWithContent .container').css('padding-left')) + parseInt($('.block--imageWithContent .container').css('margin-left'))) * -1

    // max image width + offset + gutter - container width
    var pinnedRightOffset = ($('.grid--imageWithContent .grid-1of2.grid-1of1--palm').outerWidth() + pinnedOffset*-1 - 40) + pinnedOffset + 30 - $('.grid--imageWithContent .grid-1of2.grid-1of1--palm').outerWidth()

    $('.imageWithContent-image--pinned.imageWithContent-image--left').css('margin-left', pinnedOffset);
    $('.imageWithContent-image--pinned.imageWithContent-image--right').css('margin-left', pinnedRightOffset * -1);
    $('.imageWithContent-image--pinned.imageWithContent-image--right').css('margin-right', pinnedOffset);
    $('.imageWithContent-image--pinned').css('max-width', $('.grid--imageWithContent .grid-1of2.grid-1of1--palm').outerWidth() + pinnedOffset*-1 - 40);


    var footerCalloutOffset = $('.footerCallout').height() / 2
    var lastBlockPadding = parseInt($('.mainContent .block:last-child').css('padding-bottom'))
    if ($(window).width() > 745 && !$('.page-template-template-categories').length || $(window).width() > 745 && $('.page-template-template-categories').length && $('.blocksCategories').length) {
      paddingOffset = footerCalloutOffset + lastBlockPadding
      $('.mainContent .block:last-child:not(.block--imageCallout)').css('cssText', 'padding-bottom:' + paddingOffset + 'px !important;');
    }

    if ($(window).width() < 746) {
      $('.grid--splitContent .grid-1of2.grid-1of1--palm:first-child .splitContentColumn').css('padding-top', $('.splitContent-image').height()/2 + 20);
      $('.block--splitContent').css('margin-top', $('.splitContent-image').height()/2);
    }

    $(window).on('resize', function() {
      if ($(window).width() > 767 && $(window).width() < 1024) {
        $('.block--hero--single .container').each(function(index, el) {
          var height = $(this).children('.heroSingle').height()
          $(this).height(height)
        });
      }

      $('.iconGridItem-icon').matchHeight()
      $('.iconGridItem-title').matchHeight()
      $('.grid--categories .grid-1of3 .categoryItems .cardItem .cardItem-contentContainer').matchHeight()
      $('.grid--categories .grid-1of3 .categoryItems .categoryList').matchHeight()
      $('.grid--categoryCtas .grid-1of1--palm .contentCta').matchHeight()
      $('.grid--search .grid-1of3 .cardItem .cardItem-contentContainer').matchHeight()


      if ($(window).width() < 746) {
        $('.grid--splitContent .grid-1of2.grid-1of1--palm:first-child .splitContentColumn').css('padding-top', $('.splitContent-image').height()/2 + 20);
        $('.block--splitContent').css('margin-top', $('.splitContent-image').height()/2);
      }

      $('.imageWithContent-image--pinned.imageWithContent-image--left').css('margin-left', pinnedOffset);
      $('.imageWithContent-image--pinned.imageWithContent-image--right').css('margin-left', pinnedRightOffset * -1);
      $('.imageWithContent-image--pinned.imageWithContent-image--right').css('margin-right', pinnedOffset);
      $('.imageWithContent-image--pinned').css('max-width', $('.grid--imageWithContent .grid-1of2.grid-1of1--palm').outerWidth() + pinnedOffset*-1 - 40);

      var footerCalloutMargin = $('.footerCallout').height() / 2 * -1
      if ($(window).width() > 745 && !$('.page-template-template-categories').length || $(window).width() > 745 && $('.page-template-template-categories').length && $('.blocksCategories').length) {
        $('.footerCallout').css('margin-top', footerCalloutMargin);
      }
    })

    lightbox.option({
      'alwaysShowNavOnTouchDevices': true,
      'wrapAround': true
    })

    $('.hero-links').on('change', function(e) {
      e.preventDefault()

      if ($(this).val() !== '#') {
        window.location.href = $(this).val()
      }
    })
  })
})(jQuery);
