(function($) {
  $( document ).ready(function() {
    $(".js-modal-btn").click(function(event) {
      event.preventDefault()
    })
    $(".js-modal-btn").modalVideo({channel:'youtube',
      youtube: {
        autoplay: 0,
      }
    })
  })
})(jQuery);
