(function($) {
  $( document ).ready(function() {
    $(window).scroll(function(){
      if ($('div[class*="base_"] > div').height() > 0) {
        if (parseInt($('.footer').css('marginBottom')) <= 0) {
          if ($(window).width() < 746) {
            $('.footer').css('margin-bottom', 165);
          } else {
            $('.footer').css('margin-bottom', 133);
          }
        }
      }
    })
  })
})(jQuery);
