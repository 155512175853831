(function($) {
  $( document ).ready(function() {
    var viewport = viewport();
    function viewport() {
      var e = window, a = 'inner';
      if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
      }
      return e[ a+'Width' ];
    }

    $('.hamburger').click(function() {
      $(this).toggleClass('is-active')
      $('.navRight').toggleClass('is-active');
      $('body').toggleClass('u-noScroll');
    });

    $('.menu-item-has-children').append('<div class="beforeOverlay"></div>')

    $('.menu-item-has-children .beforeOverlay').each(function(index, el) {
      var target = $(this).siblings('.sub-menu')[0]
      $(this).css('height', $(target).children('li').length * 30 + 50 + 40);
    });

    if (viewport <= 1075) {
      $('.menu-item-has-children > a').click(function(e) {
        e.preventDefault()
        $(this).siblings().toggleClass('active');
        $(this).parent().toggleClass('active');
      });
    }
  })
})(jQuery);
