(function($) {
  $( document ).ready(function() {
    var viewport = viewport();
    function viewport() {
      var e = window, a = 'inner';
      if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
      }
      return e[ a+'Width' ];
    }

    $('.postItem-title').matchHeight()
    $('.postItem-excerpt').matchHeight()
    // $('.lb-inpage-item-image').matchHeight()

    $('.heroSingle-thumbnail').each(function(index, el) {
      offsetMargin = parseInt($(this).closest('.container').css('margin-left'))
      offsetPadding = parseInt($(this).closest('.container').css('padding-left'))

      $(this).css('margin-left', (offsetPadding + offsetMargin + 1) * -1 );
    });

    $(window).on('resize', function() {
      $('.heroSingle-thumbnail').each(function(index, el) {
        offsetMargin = parseInt($(this).closest('.container').css('margin-left'))
        offsetPadding = parseInt($(this).closest('.container').css('padding-left'))

        $(this).css('margin-left', (offsetPadding + offsetMargin + 1) * -1 );
      });
    })
  })
})(jQuery);
