(function($) {
  $( document ).ready(function() {
    new WOW({
      boxClass:     'wow',
      animateClass: 'animated',
      offset:       50,
      mobile:       true,
      live:         true
    }).init();
  });
})(jQuery);
